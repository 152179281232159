import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-logs-tooltip-icon',
  standalone: true,
  template: `<span
    [style.color]="color ?? ''"
    [ngClass]="outlined !== undefined ? 'material-icons material-icons-outlined' : 'material-icons'"
  > {{ icon }} </span><ng-content></ng-content>`,
  styles: [`
    :host {
      display: block;
      display: flex;
      align-content: center;
    }
    .material-icons {
      display: inline-block;
      width: 16px;
      height: 16px;
      font-size: 16px;
      margin-right: 4px;
    }
  `],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsTooltipIconComponent {
  @Input() color?: string;
  @Input() outlined?: "";
  @Input() icon: string;
}
